<template>
  <form @submit.prevent="confirmUpdate()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Standard</ion-label>
            <ion-select :value="standard" disabled>
              <ion-select-option :value="standard">
                Standard {{ standard }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Section</ion-label>
            <ion-select :value="section" disabled>
              <ion-select-option :value="section">
                Section {{ section }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.teacher.$error }">
            <ion-label position="floating">Class teacher</ion-label>
            <ion-select v-model="teacher" :value="teacher">
              <ion-select-option
                v-for="teacher of teachers"
                :key="teacher.id"
                :value="teacher.id"
              >
                {{ teacher.user.user_detail.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-input type="hidden" v-model="id"></ion-input>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonText,
  IonIcon,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonText,
    IonIcon,
  },
  data() {
    return {
      id: this.resource.id,
      standard: this.resource.standard.name,
      section: this.resource.section.name,
      teacher: null,

      alertCircleOutline,
      alertCircleSharp,

      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    teachers() {
      return this.$store.getters["teacher/allResources"];
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      id: {
        required: helpers.withMessage("cannot be empty", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      teacher: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmUpdate() {
      const header = `Confirm`;
      const message = "Are you sure you want to save changes?";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.updateClass();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async updateClass() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        id: this.id,
        teacher_id: this.teacher,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("class/updateResource", data);
        spinner.dismiss();

        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async fetchTeachers() {
      await this.$store.dispatch("teacher/allResources");
      this.teacher = this.resource.teacher.id;
    },
  },

  created() {
    this.fetchTeachers();
  },
};
</script>
