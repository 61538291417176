<template>
  <ion-list v-if="resources != null">
    <ion-item
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
      class="hydrated link"
    >
      <ion-label @click="openPopover(item, $event)">
        <h2>
          {{ item.standard.name }} -
          {{ item.section.name }}
        </h2>
        <p>
          {{ item.students_count }} students ({{
            item.teacher.user.user_detail.name
          }})
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No classes found</p>
    </ion-list-header>
  </ion-list>

  <!-- Model -->
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal
      title="Update class teacher"
      @closeModal="setUpdateModalState(false)"
    >
      <update-class
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-class>
    </base-modal>
  </ion-modal>

  <!-- Popover -->
  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="selectedResource.standard.name"
    >
      <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'class.show',
          params: { class: selectedResource.id },
        }"
        >Students</ion-item
      >
      <ion-item button @click="setResourceToUpdate(selectedResource.id)"
        >Edit</ion-item
      >
      <ion-item
        button
        @click="
          confirmDelete({
            id: selectedResource.id,
            name: selectedResource.name,
          })
        "
        >Delete</ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import BaseModal from "../../../Base/BaseModal";
import UpdateClass from "../Class/UpdateClass";
import BasePopover from "../../../Base/BasePopover.vue";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonPopover,
  IonItem,
  IonModal,
  alertController,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonPopover,
    IonItem,
    IonModal,
    BaseModal,
    BasePopover,
    UpdateClass,
  },
  data() {
    return {
      updateModalState: false,

      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      resourceToUpdate: {
        id: null,
        name: null,
        hierachy: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      this.setPopoverState(false);
      const header = `Delete class ${resource.name} ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(data) {
      try {
        await this.$store.dispatch("class/deleteResource", data);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
      if (state == false) {
        this.setPopoverState(false);
      }
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },
  },
};
</script>
